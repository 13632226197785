// ---------------------------------------------------------
// custom font
// ---------------------------------------------------------
@font-face {
    font-family: light;
    src: url(./inter/Inter-Light.otf) format('opentype');
}

@font-face {
    font-family: regular;
    src: url(./inter/Inter-Regular.otf) format('opentype');
}

@font-face {
    font-family: medium;
    src: url(./inter/Inter-Medium.otf) format('opentype');
}

@font-face {
    font-family: semibold;
    src: url(./inter/Inter-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: bold;
    src: url(./inter/Inter-Bold.otf) format('opentype');
}

// ---------------------------------------------------------
// global styling settings
// ---------------------------------------------------------
body{
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    height: 100%;
    width: 100%;
}

html {
    scroll-behaviour: smooth;
}