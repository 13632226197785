.root_div{
    height: fit-content;
    width: 100%;
    flex-direction: row;
    //border: 1px solid red;
}

.draw_div{
    position: relative;
    width: fit-content;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
    //border: 1px solid blue;
}

.circular_progress_div{
    width: fit-content;
    padding-top: 70px;
    padding-bottom: 70px;
    margin: 0 auto;
    display: flex;
    align-items: flex-end;
    height: fit-content;
    transition: all 0.15s ease-in-out;
    will-change: opacity;
    //border: 1px solid green;
}

.buttons_div{
    display: flex;
    flex-basis: 100%;
    flex-wrap: nowrap;
    align-content: stretch;
    gap: 10px;
    margin-top: 15px;
    //border: 1px solid red;
}

.button_div{
    height: fit-content;
    flex: 1;
}