body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@property --gradient-angle {
  syntax: "<angle>";
  initial-value: Odeg;
  inherits: false;
}

.video-js {
  width: 700px;
  height: auto;
  aspect-ratio: 16/ 9;
  display: block
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}


.animation-pulse {
  /*box-shadow: 0 0 0 20px rgba(0,0,0, 0.5);
  transform: scale(0.8);*/
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.8);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.8);
  }
}

#hero_pattern {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.absolute_center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.upload_button {
  border-radius: inherit;
  position: relative;
  border-radius: inherit;

  background: white;
}

.upload_button::before,
.upload_button::after {
  content: "";
  position: absolute;
  inset: -0.1rem;
  z-index: -1;
  background: conic-gradient(from 0deg,
      var(--color-cyan-300),
      var(--color-primary-500),
      var(--color-secondary-300),
      var(--color-primary-500),
      var(--color-cyan-300));
  border-radius: inherit;
  animation: rotation2 15s linear infinite;
}

.upload_button::after {
  filter: blur(1rem);
}

.card {
  position: relative;
  background-color: rgba(255, 255, 255, 0.1);
}

.card::before,
.card-border {
  border-radius: inherit;
  content: "";
  height: 100%;
  left: 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms;
}

.card:hover::before {
  opacity: 1
}

#cards:hover>.card>.card-border {
  opacity: 1
}

.card::before {
  background: radial-gradient(800px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.06), transparent 40%);
  z-index: 3;
}

.card-border {
  background: radial-gradient(400px circle at var(--mouse-x) var(--mouse-y), rgba(255, 255, 255, 0.3), transparent 40%);
  z-index: 1;
}

.hide-scrollbar {
  /* For Internet Explorer, Edge */
  -ms-overflow-style: none;

  /* For Firefox */
  scrollbar-width: none;

  /* For Chrome, Safari, Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.custom-scrollbar {
  /* This will hide the default scrollbar */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */

  /* Custom scrollbar for Webkit browsers */
  &::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
  }

  &::-webkit-scrollbar-track {
    background: transparent; /* Track background */
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1; /* Thumb color */
    border-radius: 4px; /* Rounded corners for the thumb */
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #a8a8a8; /* Thumb color on hover */
  }
}


@keyframes rotation2 {
  0% {
    transform: rotate(0)
  }

  100% {
    transform: rotate(360deg)
  }
}

@keyframes gradientRotation {
  0% {
    --gradient-angle: 0deg;
  }

  100% {
    --gradient-angle: 360deg;
  }
}