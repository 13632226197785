@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.95, 0.95);
    }
    100% {
        transform: scale(1, 1);
    }
}

.root_div{
    height: 80%;
    max-width: 80vw;
    border-radius: 8px;
    display: flex;
    flex-wrap: no-wrap;
    align-content: flex-start;
    flex-direction: column;
    overflow: visible;
    margin: 0 auto;
}

.top_bar_div{
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    gap: 10px;
    height: fit-content;
    flex-wrap: nowrap;
    // padding-top: 5px;
    padding-bottom: 5px;
    justify-content: space-between;
    //border: 1px solid blue;
}

.top_bar_bottom_separator{
    height: 1px;
    position: absolute;
    left: 20px;
    right: 20px;
    bottom: 0px;
}

.top_bar_title_div{
    height: fit-content;
    width: fit-content;
    margin: 10px;
    margin-left: 20px;
    //border: 1px solid red;
}

.top_bar_title_light{
    width: fit-content;
    font-family: light;
    font-size: 11px;
    flex-basis: 100%;
    opacity: 0.7;
}

.top_bar_title_bold{
    width: fit-content;
    font-family: semibold;
    font-size: 15px;
}

.buttons_div{
    /*position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    margin: 0 auto;*/
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    //border: 1px solid red;
}

.new_delimited_area_div{
    padding: 10px;
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    will-change: opacity;
    opacity: 1.0;
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.new_delimited_area_div[animateNewDelimitedAreaButton='1'] {
    animation: zoom-in-zoom-out 0.25s 1;
}

.new_delimited_area_div:hover{
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.new_image_div{
    height: fit-content;
    width: fit-content;
    margin-left: 10px;
    //border: 1px solid red;
}

.new_image_div[animateNewImageButton='1'] {
    animation: zoom-in-zoom-out 0.25s 1;
}

.new_image_div:hover{
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.vertical_separator{
    margin-left: 10px;
    height: 30px;
    width: 1px;
}

.clear_all_div{
    padding: 10px;
    height: fit-content;
    width: fit-content;
    border-radius: 10px;
    cursor: pointer;
    will-change: opacity;
    opacity: 1.0;
    margin-left: 10px;
    transition: all 0.2s ease-in-out;
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
}

.clear_all_div[animateClearAllButton='1'] {
    animation: zoom-in-zoom-out 0.25s 1;
}

.clear_all_div:hover{
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.button_icon{
    height: 17px;
    width: 17px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.close_button_div{
    margin-right: 15px;
    padding: 7px;
    border-radius: 200px;
    cursor: pointer;
    opacity: 1.0;
    will-change: opacity;
    transition: all 0.2s ease-in-out;
}

.close_button_div:hover{
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.close_button{
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 20;
    opacity: 0.5;
}

.draw_div{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 15px;
    overflow-y: scroll;
    overflow-x: hidden;
    //border: 1px solid green;
}

.list_div{
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    overflow: scroll;
    gap: 5px;
    margin: 15px;
    margin-top: -5px;
    //border: 1px solid green;
}

.list_title{
    font-family: semibold;
    font-size: 12px;
    opacity: 0.7;
    flex-basis: 100%;
}

.list_inner_div{
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 5px;
    padding-bottom: 5px;
    overflow: visible;
    //border: 1px solid yellow;
}

.no_delimited_areas_div{
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    //border: 1px solid yellow;
}

.no_delimited_areas_text{
    margin: 0 auto;
    text-align: center;
    font-family: semibold;
    font-size: 13px;
    opacity: 0.7;
    flex-basis: 100%;
    //border: 1px solid yellow;
}