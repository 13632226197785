@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(0.95, 0.95);
    }
    100% {
        transform: scale(1, 1);
    }
}

.delimited_areas_list_root_div{
    //padding: 5px;
    border-radius: 6px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    overflow: visible;
    //border: 1px solid red;
}

.text_super_div{
    flex-basis: 100%;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px;
    margin: 5px;
    //border: 1px solid blue;
}

.title_div{
    flex: 1;
    flex-basis: 100%;
    display: flex;
    gap: 5px;
    //border: 1px solid black;
}

.trailing_title_text{
    font-family: light;
    opacity: 0.5;
    font-size: 14px;
    //border: 1px solid red;
}

.title_text{
    font-family: semibold;
    font-size: 14px;
    //border: 1px solid green;
}

.description_div{
    margin-top: 5px;
    flex: 1;
    flex-basis: 100%;
}

.description_text{
    opacity: 0.8;
    font-family: regular;
    font-size: 13px;
}

.description_text bold{
    opacity: 0.8;
    font-family: semibold;
    font-size: 13px;
}

.delimited_area_fragment_root_div{
    flex-basis: 100%;
    border-radius: 8px;
    display: flex;
    flex-wrap: nowrap;
    height: fit-content;
    position: relative;
    overflow: visible;
    transition: all 0.15s ease-in-out;
}

.delimited_area_fragment_root_inner_div{
    height: fit-content;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    position: relative;
    overflow: visible;
    padding: 5px;
    gap: 5px;
    //border: 1px solid green;
}

.delimited_area_fragment_colour_div{
    width: 150px;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    align-self: stretch;
    position: relative;
    overflow: hidden;
    transition: all 0.15s ease-in-out;
    will-change: opacity;
    //border: 1px solid green;
}

.delimited_area_fragment_colour_div_background{
    position: absolute;
    left: 0;
    height: 100%;
    width: 60px;
    border-radius: 6px;
}

.delimited_area_fragment_colour_inner_div{
    height: fit-content;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    border: 1px solid green;
}

.delimited_area_fragment_colour_code_div{
    flex-basis: 100%;
    width: fit-content;
    z-index: 2;
    transition: all 0.25s ease-in-out;
}

.delimited_area_fragment_colour_code_inner_div{
    border-radius: 20px;
    width: fit-content;
    margin: 0 auto;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: rgba(255, 255, 255, 1.0);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    
    //border: 1px solid black;
}

.delimited_area_fragment_colour_code{
    font-family: semibold;
    color: rgba(110, 110, 110, 1.0);
    font-size: 10px;
}

.done_button_div{
    padding: 7px;
    border-radius: 200px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 1.0);
    // opacity: 1.0;
    will-change: opacity;
    z-index: 1;
    transition: all 0.15s ease-in-out;
}

.done_button_div[animateDoneButton='1'] {
    animation: zoom-in-zoom-out 0.25s 1;
}

.done_button_icon{
    width: 12px;
    height: 12px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    z-index: 20;
    opacity: 0.5;
}

.colour_picker_div{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: fit-content;
    width: 90%;
    z-index: 300;
    transition: all 0.15s ease-in-out;
    //border: 1px solid red;
}

.blurred_background_div{
    height: 100%;
    width: 100%;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 299;
    cursor: pointer;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    transition: all 0.2s ease-in-out;
    will-change: opacity;
}

.delimited_area_fragment_details_div{
    position: relative;
    flex: 1;
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 6px;
    padding-right: 2px;
    //border: 1px solid red;
}

.delimited_area_fragment_detail_div{
    flex-basis: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 10px;
    height: fit-content;
    //border: 1px solid green;
}

.delimited_area_fragment_title{
    font-family: light;
    font-size: 10px;
    // width: 75px;
    // text-align: center;
    //border: 1px solid red;
}

.delimited_area_fragment_textfield_div{
    flex: 1;
    //border: 1px solid red;
}

.delimited_area_fragment_description{
    flex: 1;
    font-family: semibold;
    font-size: 11px;
}

.horizontal_separator{
    height: 1px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 5px;
    margin-right: 2px;
    flex-basis: 100%;
    //border: 1px solid red;
}

.delete_div{
    height: fit-content;
    width: fit-content;
    padding: 5px;
    position: absolute;
    left: -4px;
    top: -8px;
    border-radius: 200px;
    cursor: pointer;
    opacity: 1.0;
    transition: all 0.15s ease-in-out;
    will-change: opacity;
}

.delete_div:hover{
    opacity: 0.9;
    transition: all 0.15s ease-in-out;
}

.delete_icon{
    margin: 0 auto;
    height: 10px;
    width: 10px;
    display: flex;
    align-items: center;
    //border: 1px solid blue;
}

.add_or_edit_button_div{
    margin-left: 3px;
    border-radius: 20px;
    padding: 3px;
    padding-left: 10px;
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    align-items: center;
    padding-right: 10px;
    cursor: pointer;
    opacity: 1.0;
    transition: all 0.2s ease-in-out;
    will-change: opacity;
}

.add_or_edit_button_div:hover{
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
}

.add_or_edit_button_text{
    font-family: semibold;
    font-size: 10px;
    color: rgba(255, 255, 255, 1.0);
}

.delimited_areas_list_div{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    //border: 1px solid red;
}

.no_delimited_areas_div{
    flex: 1;
    height: 50px;
    margin: 10px;
    margin-top: -5px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    //border: 1px solid yellow;
}

.no_delimited_areas_text{
    margin: 0 auto;
    text-align: center;
    font-family: semibold;
    font-size: 13px;
    opacity: 0.7;
    flex-basis: 100%;
    //border: 1px solid yellow;
}